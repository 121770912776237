import type { To } from "react-router-dom";
import type { Destination } from "../contract/routing/Destination";

export enum NavigationDirection {
  Back = 0,
  Forward = 1,
}

export function addNavigationDirection(
  to: To,
  direction: NavigationDirection,
): Destination<unknown> {
  return {
    to,
    state: {
      direction,
    },
  };
}
