import { useSyncExternalStore, useEffect } from "react";
import { ConnectionStatus } from "./ConnectionStatus";
import { webSocketManager } from "./WebSocketManager";
import { api } from "./ApiClient";
import type { PaymentSubscriptionSubject } from "./PaymentSubscriptionSubject";
import type { UpdateNotification } from "../contract/updates/UpdateNotification";
import UpdateType from "../contract/updates/UpdateType";
import type { TicketViewModel } from "./TicketViewModel";

interface PaymentTicketParams {
  paymentId: string;
  signature: string;
}

function getPaymentTicket({
  paymentId,
  signature,
}: PaymentTicketParams): Promise<TicketViewModel<PaymentSubscriptionSubject>> {
  return api.post(
    `ticket/payment/${paymentId}?signature=${encodeURIComponent(signature)}`,
  );
}

class SubscriptionManager<
  TTicketParams extends object,
  TSubject extends object,
> {
  private _subscriptions: {
    [key: string]: { cleanup: () => void };
  } = {};

  private _tickets: {
    data: TTicketParams;
    ticket: TicketViewModel<unknown>;
  }[] = [];

  public subscriptionStatus: { [key: string]: boolean } = {};

  constructor(
    public subject: string,
    public key: (data: TTicketParams) => string,
    public getTicket: (
      data: TTicketParams,
    ) => Promise<TicketViewModel<TSubject>>,
  ) {}

  async onConnect(data: TTicketParams) {
    if (webSocketManager.status === ConnectionStatus.Connected) {
      const ticket = await this.getTicket(data);

      this._tickets.push({ data, ticket });

      sendSubscriptionRequest(this.subject, ticket);
    }
  }

  subscribe(data: TTicketParams) {
    const key = this.key(data);
    let sub = this._subscriptions[key];

    if (!sub) {
      if (webSocketManager.status === ConnectionStatus.Connected) {
        this.onConnect(data);
      }

      const cleanupOnStatus = webSocketManager.subscribeToStatus(() => {
        if (webSocketManager.status === ConnectionStatus.Connected) {
          this.onConnect(data);
        } else {
          this._updateSubscriptionStatus(data, false);
        }
      });

      const cleanupOnMessage = webSocketManager.onMessage((event) => {
        const update = JSON.parse(event.data) as UpdateNotification;
        if (update.updateType === UpdateType.SubscriptionCreated) {
          const ticket = this._tickets.find(
            (x) => x.ticket.ticketId === update.ticketId,
          );

          if (!ticket) return;

          this._updateSubscriptionStatus(ticket.data, true);
        }
      });

      sub = {
        cleanup: () => {
          cleanupOnStatus();
          cleanupOnMessage();
        },
      };

      this._subscriptions[key] = sub;
    }

    return sub.cleanup;
  }

  private _updateSubscriptionStatus(
    data: TTicketParams,
    isSubscribed: boolean,
  ) {
    this.subscriptionStatus = {
      ...this.subscriptionStatus,
      [this.key(data)]: isSubscribed,
    };
    this._notifyStatusListeners();
  }

  private _notifyStatusListeners() {
    for (const listener of this._statusListeners) listener();
  }

  private _statusListeners: (() => void)[] = [];

  onStatusUpdated(listener: () => void) {
    this._statusListeners.push(listener);

    return () => {
      const index = this._statusListeners.indexOf(listener);
      if (index !== -1) {
        this._statusListeners.splice(index, 1);
      }
    };
  }
}

const paymentSubscriptionManager = new SubscriptionManager<
  PaymentTicketParams,
  PaymentSubscriptionSubject
>("PaymentSubscriptionSubject", (x) => x.paymentId, getPaymentTicket);

type SubscriptionHook<T> = (data: T) => boolean;

function createSubscriptionStore<
  TTicketParams extends object,
  TSubject extends object,
>(manager: SubscriptionManager<TTicketParams, TSubject>) {
  return {
    subscribe(listener: () => void) {
      return manager.onStatusUpdated(listener);
    },
    getSnapshot() {
      return manager.subscriptionStatus;
    },
    getServerSnapshot() {
      return manager.subscriptionStatus;
    },
  };
}

function createSubscriptionHook<
  TTicketParams extends object,
  TSubject extends object,
>(
  manager: SubscriptionManager<TTicketParams, TSubject>,
): SubscriptionHook<TTicketParams> {
  const store = createSubscriptionStore(manager);

  return function useSubscription(data) {
    const state = useSyncExternalStore(
      store.subscribe,
      store.getSnapshot,
      store.getServerSnapshot,
    );

    useEffect(() => manager.subscribe(data), [manager.subscribe, data]);

    const key = manager.key(data);
    const isSubscribed = state[key];

    return isSubscribed;
  };
}

export const usePaymentSubscription = createSubscriptionHook(
  paymentSubscriptionManager,
);

export function sendSubscriptionRequest<TSubject extends object>(
  subject: string,
  ticket: TicketViewModel<TSubject>,
) {
  webSocketManager.socket?.send(
    JSON.stringify({
      action: "SubscriptionRequest",
      data: {
        subject,
        ticket: ticket.token,
      },
    }),
  );
}
