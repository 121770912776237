import { Button } from "@naf/teamscheme";
import { AppPages } from "../../routing/AppPages";
import { getAbsolutePath } from "../../routing/AppRoutes";

export function NotFoundContent() {
  return (
    <div>
      <p>Siden finnes ikke.</p>
      <p>
        Det kan skyldes at siden er utdatert eller har blitt flyttet. Du kan gå
        tilbake eller hoppe til startsiden. Kom du hit med en lenke på SMS,
        sjekk at du bruker den siste lenken du mottok.
      </p>
      <Button
        variant="primary"
        linkTo={getAbsolutePath({ page: AppPages.LandingPage })}
      >
        Gå til startsiden
      </Button>
    </div>
  );
}
