import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";

export enum ContractTypeKey {
  None = "Ingen",
  Simple = "Enkel",
  Standard = "Standard",
  Member = "Medlem",
  Premium = "Premium",
}

export function getContractTypeName(contractType: ContractType) {
  switch (contractType) {
    case ContractType.None:
      return "Ingen";
    case ContractType.Simple:
      return "Enkel";
    case ContractType.Standard:
      return "Standard";
    case ContractType.Member:
    case ContractType.Premium:
      return "Premium";
    default:
      throw new Error(`Contract type ${contractType} not found!`);
  }
}

export function getContractTypeKey(contractType: ContractType) {
  switch (contractType) {
    case ContractType.None:
      return ContractTypeKey.None;
    case ContractType.Simple:
      return ContractTypeKey.Simple;
    case ContractType.Standard:
      return ContractTypeKey.Standard;
    case ContractType.Member:
      return ContractTypeKey.Member;
    case ContractType.Premium:
      return ContractTypeKey.Premium;
    default:
      throw new Error(`Contract type ${contractType} not found!`);
  }
}

export function getContractTypeKeySoft(contractType: ContractType | undefined) {
  if (contractType === undefined) {
    return null;
  }

  try {
    const typeKey = getContractTypeKey(contractType);
    return typeKey;
  } catch (error) {
    return null;
  }
}

export function getContractType(contractTypeName: ContractTypeKey) {
  switch (contractTypeName) {
    case ContractTypeKey.None:
      return ContractType.None;
    case ContractTypeKey.Simple:
      return ContractType.Simple;
    case ContractTypeKey.Standard:
      return ContractType.Standard;
    case ContractTypeKey.Premium:
      return ContractType.Premium;
    case ContractTypeKey.Member:
      return ContractType.Member;
    default:
      return null;
  }
}
