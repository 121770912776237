// https://vike.dev/onRenderClient
export { onRenderClient };

import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import type { OnRenderClientAsync } from "vike/types";
import { AppTrackingProvider } from "../tracking/Tracking";
import { routes } from "../routing/AppRouter";
import { StandaloneErrorBoundary } from "../StandaloneErrorBoundary";

const onRenderClient: OnRenderClientAsync = async (pageContext) => {
  import("@sentry/browser").then((Sentry) => {
    Sentry.init({
      dsn: "https://baf6bcb4e6ac4efeb30632b56345ac6b@sentry.io/2035715",
      enabled: document.location.hostname === "kjopekontrakt.naf.no",
      environment: document.location.hostname,
      release: import.meta.env.PUBLIC_ENV__COMMIT_ID,
      debug: false,
      integrations: [
        Sentry.dedupeIntegration,
        Sentry.browserTracingIntegration(),
      ],
      tracesSampleRate: 0.05,
      tracePropagationTargets: [/^\/api/],
      ignoreErrors: ["Non-Error promise rejection captured"],
      tunnel: "/api/sentry/tunnel",
    });
  });

  const container = document.getElementById("page-view");
  if (!container) throw new Error("No root found");
  const router = createBrowserRouter(routes, {
    future: {
      v7_partialHydration: true,
    },
  });
  const page = (
    <StandaloneErrorBoundary>
      <AppTrackingProvider>
        <RouterProvider router={router} />
      </AppTrackingProvider>
    </StandaloneErrorBoundary>
  );

  let root: ReactDOM.Root;
  if (pageContext.isHydration && pageContext.config.hydrate) {
    const title = pageContext.config.documentProps?.title;
    if (title) {
      document.title = title;
    }
    root = ReactDOM.hydrateRoot(container, page, {
      onRecoverableError(err, info) {
        import("@sentry/browser").then((Sentry) =>
          Sentry.captureException(err, { extra: { info } }),
        );
        console.error({ err, info });
      },
    });
  } else {
    root = ReactDOM.createRoot(container);
    root.render(page);
  }
};
