import { ClickBackLink, LinkBackLink } from "@naf/teamscheme";
import { onDesktop } from "@naf/teamscheme";
import { type ReactNode, forwardRef } from "react";
import styled from "styled-components";
import { NavigationDirection } from "../routing/NavigationDirection";
import Breadcrumbs, {
  type BreadcrumbsProps,
} from "../shared/elements/Breadcrumbs";
import Card from "./Card";
import { PageContent } from "./PageContent";
import type { Destination } from "../contract/routing/Destination";

export interface PageCardBackButtonProps {
  back?: Destination<unknown>;
  breadcrumbs?: BreadcrumbsProps;
  onClickBack?: () => void;
}

export function PageCardBackButton({
  back,
  breadcrumbs,
  onClickBack,
}: PageCardBackButtonProps) {
  if (back) {
    return (
      <LinkBackLink
        to={back.to}
        state={{
          ...(typeof back.state === "object" ? back.state : {}),
          direction: NavigationDirection.Back,
        }}
        onClick={onClickBack}
      >
        Tilbake
      </LinkBackLink>
    );
  }

  if (breadcrumbs) {
    return (
      <Breadcrumbs
        items={breadcrumbs.items}
        currentPage={breadcrumbs.currentPage}
      />
    );
  }

  if (onClickBack) {
    return <ClickBackLink onClick={onClickBack} />;
  }

  return null;
}

export interface PageCardProps extends PageCardBackButtonProps {
  children: ReactNode;
  className?: string;
}

export const PageCardContent = styled.div``;

const PageCardTop = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.space16};
`;

const PageCardInner = forwardRef<HTMLDivElement | null, PageCardProps>(
  function ({ children, back, onClickBack, breadcrumbs, className }, ref) {
    return (
      <PageContent>
        <Card ref={ref} className={className}>
          {back || onClickBack || breadcrumbs ? (
            <PageCardTop>
              <PageCardBackButton
                back={back}
                onClickBack={onClickBack}
                breadcrumbs={breadcrumbs}
              />
            </PageCardTop>
          ) : null}
          <PageCardContent>{children}</PageCardContent>
        </Card>
      </PageContent>
    );
  },
);

const PageCard = styled(PageCardInner)`
  ${onDesktop<PageCardProps>`
    ${Card} {
      margin-bottom: 2em;
    }
  `}
`;

export default PageCard;
