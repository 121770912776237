import type { ReactNode } from "react";
import {
  type CtaClickProps,
  type ITrackingContext as ITeamschemeTrackingContext,
  type TrackEventProps,
  TrackingContext as TeamschemeTrackingContext,
  type TrackingValue,
} from "@naf/teamscheme";
import type { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { roleName } from "../shared/roleName";
import type { ContractViewModel } from "../contract/model/ContractViewModel";
import { normalizeComponentTree } from "../shared/utils/normalizeComponentTree";
import { TrackingContext, type ITrackingContext } from "./TrackingContext";

interface TrackEventEventParams {
  contract_context: string;
  contract_action: string;
  contract_detail?: string;
  contract_value?: TrackingValue;
  contract_additional_props?: {
    [key: string]: TrackingValue;
  };
}

export function AppTrackingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  function trackCtaClick({ navigate, label }: CtaClickProps) {
    const location = window.location;

    let destination: string | undefined = undefined;
    if (typeof navigate === "string") {
      destination = navigate;
    } else if (navigate) {
      destination = navigate.pathname;
    }

    let hostname: string | undefined = undefined;
    let absoluteDestinationPath: string | undefined = undefined;
    if (destination) {
      if (destination.startsWith("http")) {
        hostname = new URL(destination).hostname;
        absoluteDestinationPath = destination;
      } else if (!destination.startsWith("/")) {
        /*
          If destination does not start with the http-protocol we must assume we
          are on our own domain, so hostname of target must be origin of where we are.
        */
        hostname = location.origin;
        absoluteDestinationPath = `${hostname}/${destination}`;
      } else {
        hostname = location.origin;
        absoluteDestinationPath = hostname + destination;
      }
    }
    const eventName = "cta_click";
    const eventParams = {
      cta_location: location.pathname,
      cta_text: normalizeComponentTree(label),
      cta_hostname: location.origin,
      cta_url: absoluteDestinationPath,
    };

    window.dataLayer.push({
      event: eventName,
      event_params: eventParams,
    });

    console.log(eventName, {
      event_params: eventParams,
    });
  }

  function trackEvent({
    category: contract_context,
    action: contract_action,
    label: contract_detail,
    value: contract_value,
    additionalProps: contract_additional_props,
  }: TrackEventProps) {
    const eventName = "vehicle_contract";
    const eventParams: TrackEventEventParams = {
      contract_context,
      contract_action,
      contract_detail,
      contract_value,
      contract_additional_props,
    };

    window.dataLayer.push({
      event: eventName,
      event_params: eventParams,
    });

    console.log(eventName, {
      event_params: eventParams,
    });
  }

  function addTrackingContext(contextObject: {
    [attribute: string]: TrackingValue;
  }) {
    for (const [attribute, value] of Object.entries(contextObject)) {
      window.dataLayer.push({ [attribute]: value });
    }
  }

  function trackContractLoad(contract: ContractViewModel, role: Role) {
    const params = {
      id: contract.id,
      role: roleName(role),
    };

    window.dataLayer.push({
      event: "contract",
      ...params,
    });
  }

  function trackExperiment(experimentName: string) {
    window.dataLayer.push({
      event: "experiment",
      experimentName,
    });

    console.log("experiment", experimentName);
  }

  function trackAppLoad() {
    window.dataLayer.push({
      event: "load",
      appVersion: `${import.meta.env.PUBLIC_ENV__REACT_APP_VERSION} / ${import.meta.env.PUBLIC_ENV__COMMIT_ID}`,
    });
  }

  function trackPageView({ title, path }: { title: string; path: string }) {
    window.dataLayer.push({
      event: "virtual_page_view",
      virtualPageURL: path,
      virtualPageTitle: title,
    });

    console.log("pageview", path, title);
  }

  const trackingContext: ITeamschemeTrackingContext = {
    trackEvent,
    trackCtaClick,
  };
  const appTrackingContext: ITrackingContext = {
    ctaClick: trackCtaClick,
    event: trackEvent,
    pageView: trackPageView,
    appLoad: trackAppLoad,
    contractLoad: trackContractLoad,
    experiment: trackExperiment,
    context: addTrackingContext,
  };

  return (
    <TeamschemeTrackingContext.Provider value={trackingContext}>
      <TrackingContext.Provider value={appTrackingContext}>
        {children}
      </TrackingContext.Provider>
    </TeamschemeTrackingContext.Provider>
  );
}
