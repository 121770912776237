import {
  HeaderMenuButton,
  type MenuListItem,
  NavigationalHeaderElements,
} from "@naf/teamscheme";
import { LoadingText } from "@naf/teamscheme";
import { CheckIcon, LoadingIcon } from "@naf/teamscheme";
import { onDesktop, onMinWidthLarge } from "@naf/teamscheme";
import {
  type HTMLAttributes,
  type ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { css, useTheme } from "styled-components";
import type { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { getRoleName } from "../../contract/ContractContext";
import { useSavingState } from "../../contract/header/useSavingState";
import { SavingState } from "../../contract/header/SavingState";
import type { ContractViewModel } from "../../contract/model/ContractViewModel";
import { useWindowSize } from "../../shared/layout/WindowSize";
import { FlyoutMenu } from "./FlyoutMenu";
import { HeaderAlerts } from "./HeaderAlert";
import { HeaderVariant } from "./HeaderVariant";
import { useAuth } from "../../auth/AuthContext";
import { HintTextWrapper } from "./HintTextWrapper";
import { RoadAssistance } from "./RoadAssistance";
import { OuterHeader } from "./OuterHeader";
import { HeaderWrapper } from "./HeaderWrapper";
import { HeaderSection } from "./HeaderSection";
import { LogoHeaderSection } from "./LogoHeaderSection";
import { flexRow } from "./flexRow";
import { HeaderLogo, type HeaderLogoLinkTarget } from "./HeaderLogo";

interface HeaderProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "role" | "children"> {
  variant?: HeaderVariant;
  elements: MenuListItem[];
  contract?: ContractViewModel;
  licensePlateNumber?: string;
  role?: Role;
  logoLinkLocation?: HeaderLogoLinkTarget;
}

const NavigationHeaderSection = styled(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    function (props, ref) {
      return <HeaderSection ref={ref} {...props} />;
    },
  ),
)`
  ${flexRow}
  justify-content: flex-end;
  ${onDesktop`
    width: 60%;
  `}
`;

const HeaderMenuWrapper = styled.div`
  display: none;
  ${onDesktop`
    display: inline-flex;
    & > *:nth-child(n+2){
      display: none;
    }
  `}
  ${onMinWidthLarge`
    & > *:nth-child(n+3){
      display: none;
    }
    & > *:not(:nth-child(n+3)){
      display: inline-flex;;
    }
  `};
`;

const SavingTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme: { spacing, fontStyle } }) => css`
    ${fontStyle.bodyText.bodyText}
    margin-left: ${spacing.space24};
    ${onDesktop`
      margin-left: ${spacing.space12};
    `}
  `}
`;

export const IsLoadingText = styled.span`
  margin-left: ${(props) => props.theme.spacing.space8};
`;

function SavingText({
  timeout,
  defaultText,
}: {
  timeout: number;
  defaultText: ReactNode;
}) {
  const savingState = useSavingState(timeout);

  if (savingState === SavingState.Loading) {
    return (
      <SavingTextWrapper>
        <LoadingIcon title="Lagrer" variant="green" />
        <IsLoadingText>
          <LoadingText text="Lagrer" />
        </IsLoadingText>
      </SavingTextWrapper>
    );
  }

  if (savingState === SavingState.Saved) {
    return (
      <SavingTextWrapper>
        <CheckIcon title="Lagret" variant="park" animated />
        <IsLoadingText>Lagret!</IsLoadingText>
      </SavingTextWrapper>
    );
  }

  return defaultText ? <>{defaultText}</> : null;
}

function Hint({
  role,
  licensePlateNumber,
}: {
  role: Role;
  licensePlateNumber?: string;
}) {
  const { isAdmin } = useAuth();
  return (
    <HintTextWrapper>
      {licensePlateNumber ? <span>{licensePlateNumber}</span> : null}
      <span>
        {getRoleName(role).TitleCase}{" "}
        {isAdmin ? (
          <span>
            (<b>Admin</b>)
          </span>
        ) : null}
      </span>
    </HintTextWrapper>
  );
}

const StaticHintWrapper = styled.div`
  ${({ theme: { spacing } }) => onDesktop`
    margin-left: ${spacing.space12};
  `}
`;

function Header({
  variant,
  elements,
  contract,
  role,
  logoLinkLocation,
  licensePlateNumber,
  ...props
}: HeaderProps) {
  const headerVariant = variant || HeaderVariant.Flow;
  const isStatic = headerVariant === HeaderVariant.Static;
  const menuItems = elements;
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const menuElementsRef = useRef<{ [key: string]: Element }>(null);

  const headerMenuRef = useRef<HTMLDivElement>(null);
  const headerMenuButtonRef = useRef<HTMLDivElement>(null);
  const { width: screenWidth } = useWindowSize();
  const theme = useTheme();

  const checkClickOutside = useCallback(function (e: MouseEvent) {
    const clickedElement = e.target;
    if (!(clickedElement instanceof Node)) return;
    const clickedMenuElement =
      menuElementsRef?.current &&
      Object.values(menuElementsRef.current).find((e) => e === clickedElement);
    if (
      clickedMenuElement &&
      !headerMenuButtonRef.current?.contains(clickedElement)
    ) {
      setTimeout(() => setMenuIsOpen(false));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("mouseup", checkClickOutside);
    return () => window.removeEventListener("mouseup", checkClickOutside);
  }, [checkClickOutside]);

  useEffect(() => {
    if (screenWidth) {
      if (
        (screenWidth < theme.customBreakpoints.minDesktopWidth &&
          menuItems.length < 1) ||
        (theme.customBreakpoints.minDesktopWidth < screenWidth &&
          theme.customBreakpoints.minWidthLarge > screenWidth &&
          menuItems.length < 2) ||
        (theme.customBreakpoints.minWidthLarge < screenWidth &&
          menuItems.length < 3)
      ) {
        setMenuIsOpen(false);
      }
    }
  }, [
    menuItems.length,
    screenWidth,
    theme.customBreakpoints.minDesktopWidth,
    theme.customBreakpoints.minWidthLarge,
  ]);

  const hint =
    typeof role === "number" ? (
      contract?.licensePlateNumber ? (
        <Hint role={role} licensePlateNumber={contract.licensePlateNumber} />
      ) : (
        <Hint role={role} licensePlateNumber={licensePlateNumber} />
      )
    ) : null;

  return (
    <>
      <OuterHeader>
        <HeaderWrapper variant={headerVariant} {...props}>
          <LogoHeaderSection>
            <HeaderLogo
              linkLocation={
                isStatic || !contract?.signature?.completed
                  ? logoLinkLocation
                  : undefined
              }
            />
            {isStatic && hint && <StaticHintWrapper>{hint}</StaticHintWrapper>}
            {isStatic && !hint && <RoadAssistance />}
            {!isStatic && <SavingText timeout={5000} defaultText={hint} />}
          </LogoHeaderSection>
          <NavigationHeaderSection ref={headerMenuRef}>
            <HeaderMenuWrapper>
              <NavigationalHeaderElements elements={menuItems} />
            </HeaderMenuWrapper>
            <div ref={headerMenuButtonRef}>
              <HeaderMenuButton
                className="menuButton"
                label={menuIsOpen ? "Lukk" : "Meny"}
                isOpen={menuIsOpen}
                onClick={() => setMenuIsOpen(!menuIsOpen)}
                menuLength={menuItems.length}
              />
            </div>
          </NavigationHeaderSection>
          <FlyoutMenu
            menuItems={menuItems}
            isOpen={menuIsOpen}
            onClose={() => setMenuIsOpen(!menuIsOpen)}
          />
        </HeaderWrapper>
      </OuterHeader>
      <HeaderAlerts variant={headerVariant} />
    </>
  );
}

export default Header;
