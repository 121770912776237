import React, { useState, type ReactNode, useContext } from "react";
import type { ApiError } from "../../api/ApiClient";

interface IContractHeadeerContext {
  isSaving: boolean;
  setIsSaving(isSaving: boolean): void;
  hasSaved: boolean;
  setHasSaved(hasSaved: boolean): void;
  saveError: ApiError | null;
  setSaveError(saveError: ApiError | null): void;
  showStatic: boolean;
  setShowStatic(showStatic: boolean): void;
}

export const ContractHeaderContext =
  React.createContext<IContractHeadeerContext | null>(null);

export function useContractHeaderContext() {
  const context = useContext(ContractHeaderContext);

  if (!context) throw new Error("No ContractHeaderContext found!");

  return context;
}
