import { useEffect } from "react";

/**
 * Adds a useEffect hook that updates the title, and reverts
 * to the original on cleanup.
 *
 * @param {string} title
 */
export function useDocumentTitle(title?: string) {
  useEffect(() => {
    if (title && typeof document !== "undefined") {
      const prev = document.title;
      document.title = title;
      return () => {
        document.title = prev;
      };
    }
  }, [title]);
}

export const TITLE_SEP = "\u2013";
export const TITLE_POSTFIX = `${TITLE_SEP} NAF Kjøpekontrakt`;
