export enum Role {
  Seller = 0,
  Buyer = 1,
}

export enum RoleString {
  Seller = "Seller",
  Buyer = "Buyer",
}

export function getRoleString(role: Role) {
  switch (role) {
    case Role.Buyer:
      return RoleString.Buyer;
    case Role.Seller:
      return RoleString.Seller;
    default:
      throw new Error(`Unrecognized role "${role}"`);
  }
}

export function getRole(roleString: RoleString) {
  switch (roleString) {
    case RoleString.Buyer:
      return Role.Buyer;
    case RoleString.Seller:
      return Role.Seller;
    default:
      throw new Error(`Unrecognized role string "${roleString}"`);
  }
}
