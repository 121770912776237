import { createContext, useContext } from "react";
import type { IRestClient } from "./ApiClient";

export const ContractClientContext = createContext<IRestClient | null>(null);

export function useContractClient() {
  const context = useContext(ContractClientContext);

  if (!context) throw new Error("No ContractClientContext found!");

  return context;
}
