import { useEffect, useState } from "react";

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({});

  useEffect(() => {
    let timeout: number | null = null;

    function handleResize() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = window.setTimeout(
        () =>
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          }),
        100,
      );
    }

    window.addEventListener("resize", handleResize, { passive: true });

    handleResize();

    return () => {
      if (timeout) clearTimeout(timeout);

      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
}
