import type { CSSProperties, ReactNode } from "react";
import styled, { css } from "styled-components";

export interface ErrorMessageProps {
  style?: CSSProperties;
  className?: string;
  component?: "p" | "span";
  children: ReactNode;
}

const errorStyling = css`
  color: ${({ theme }) => theme.nafColor.information.errorDark};
`;

const StyledParagraph = styled.p`
  ${errorStyling}
`;

const StyledSpan = styled.span`
  ${errorStyling}
`;

const ErrorMessage = ({
  style,
  className,
  component,
  children,
}: ErrorMessageProps) => {
  const componentType = component || "p";
  const props = {
    style,
    className,
    children,
  };

  switch (componentType) {
    case "p":
      return <StyledParagraph {...props} />;
    case "span":
      return <StyledSpan {...props} />;
    default:
      throw new Error(`Unrecognized component "${componentType}"`);
  }
};

export default ErrorMessage;
