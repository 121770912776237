import type { HTMLAttributes } from "react";
import styled from "styled-components";
import { nafColor } from "@nafcore/theme";
import { NafLogo } from "./shared/logos/NafLogo";
import { copyFooterData } from "./shared/elements/copyFooterData";

export const StandaloneErrorPage = styled(function ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const { year, appVersion } = copyFooterData;
  return (
    <div {...props}>
      <header>
        <div>
          <NafLogo />
        </div>
        <div>
          <span>Veihjelp</span>
          <strong>08&nbsp;505</strong>
        </div>
      </header>
      <main>
        <div>{children}</div>
      </main>
      <footer>
        <p>NAF Kjøpekontrakt</p>
        <p>
          &copy; {year} NAF v{appVersion}
        </p>
      </footer>
    </div>
  );
})`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: sans-serif;

    main {
      flex: 1;
      padding: 1em;

      & > div {
        max-width: 720px;
        margin: auto;
      }
    }

    header {
      display: flex;
      padding: 1em;
      gap: 1em;
      align-items: center;

      img {
        display: block;
      }

      div:last-child {
        display: flex;
        flex-direction: column;
      }
    }

    footer {
      color: white;
      background: ${nafColor.primary.spruce};
      padding: 1em;

      p {
        margin: 0.5em 0;
      }
    }
    `;
