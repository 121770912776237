import styled from "styled-components";
import LoadingBox from "../shared/LoadingBox";
import { AdminLayout } from "./AdminLayout";

const CenteredLayout = styled(AdminLayout)`
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
`;

export function AdminLoadingPage() {
  return (
    <CenteredLayout>
      <LoadingBox text="Loading admin panel" />
    </CenteredLayout>
  );
}
