import { useEffect } from "react";
import { StaticLoadingScreen } from "../shared/LoadingScreen";
import { useLoaderData, useNavigate } from "react-router-dom";
import type { LoginCallbackLoaderResult } from "./LoginCallbackLoader";

export function LoginCallbackPage() {
  const navigate = useNavigate();
  const loaderData = useLoaderData() as LoginCallbackLoaderResult | null;
  const redirect = loaderData?.redirect;

  useEffect(() => {
    if (redirect) navigate(redirect.to, { state: redirect.state });
  }, [redirect, navigate]);

  return <StaticLoadingScreen />;
}
