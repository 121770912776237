import styled from "styled-components";
import type { MenuListItem } from "@naf/teamscheme";
import { FlyoutModal, type ModalProps } from "@naf/teamscheme";
import { ButtonLink, type ButtonLinkVariant } from "@naf/teamscheme";
import ExternalButtonLink from "../../shared/ExternalButtonLink";

const Icon = styled.span``;
const IconHover = styled.span``;
const Label = styled.div``;

const UL = styled.ul`
  list-style-type: none;

  li {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.spacing.space16};
    display: flex;
    align-items: center;

    ${Icon} {
      width: ${({ theme }) => theme.spacing.space40};
      display: flex;
      align-items: center;
      jusify-content: flex-start;
    }
    ${IconHover} {
      width: ${({ theme }) => theme.spacing.space40};
      display: none;
      align-items: center;
      jusify-content: flex-start;
      color: ${({ theme }) => theme.nafColor.primary.park};
    }
  }
  li:hover {
    ${Icon} {
      display: none;
    }
    ${IconHover} {
      display: flex;
    }
  }
`;

export function FlyoutMenu({
  menuItems,
  ...props
}: Omit<ModalProps, "children"> & { menuItems: MenuListItem[] }) {
  return (
    <FlyoutModal {...props}>
      <nav>
        <UL>
          {menuItems.map((item) => {
            const { trackEvent, onClick } = item;
            const variant: ButtonLinkVariant = "secondary";
            const children = <span>{item.text}</span>;
            const base = { variant, trackEvent, children };
            let button = null;
            if ("linkTo" in item && item.linkTo) {
              button = (
                <ButtonLink {...base} onClick={onClick} linkTo={item.linkTo} />
              );
            } else if ("href" in item && item.href) {
              button = (
                <ExternalButtonLink
                  {...base}
                  href={item.href}
                  onClick={onClick}
                />
              );
            } else if (item.onClick) {
              button = (
                <ButtonLink
                  {...base}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    if (onClick) onClick(e);
                  }}
                />
              );
            }
            return (
              <li key={item.key}>
                <Icon>{item.icon?.icon}</Icon>
                <IconHover>
                  {item.icon?.hoverIcon ? item.icon.hoverIcon : item.icon?.icon}
                </IconHover>
                <Label>{button}</Label>
              </li>
            );
          })}
        </UL>
      </nav>
    </FlyoutModal>
  );
}
