import { createContext, useContext } from "react";
import type { ApiError } from "../api/ApiClient";
import type { CustomerViewModel } from "../shared/models/CustomerViewModel";

export interface ICustomerContext {
  isLoggedIn: boolean;
  isLoading: boolean;
  result: CustomerViewModel | null;
  error: ApiError | null;
}

export const CustomerContext = createContext<ICustomerContext | null>(null);

export function useCustomer() {
  const context = useContext(CustomerContext);

  if (!context) throw new Error("No CustomerContext found!");

  return context;
}
