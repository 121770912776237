import StaticHeader from "../../layout/header/StaticHeader";
import { TITLE_POSTFIX, TITLE_SEP } from "../DocumentTitle";
import { LoadErrorPage } from "./LoadErrorPage";
import { NotFoundContent } from "./NotFoundContent";
import { NotFoundIllustration } from "./NotFoundIllustration";
import { NotFoundWrapper } from "./NotFoundWrapper";

export function NotFoundScreen() {
  return (
    <>
      <StaticHeader />
      <LoadErrorPage
        title={`404 ${TITLE_SEP} Finner ikke siden ${TITLE_POSTFIX}`}
      >
        <NotFoundWrapper>
          <NotFoundContent />
          <NotFoundIllustration />
        </NotFoundWrapper>
      </LoadErrorPage>
    </>
  );
}
