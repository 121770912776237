export const CATEGORIES = {
  ABOUT_PAGE_BUTTONS: "About Page Buttons",
  ABOUT_PAYMENT_PAGE: "About Payment Page",
  ADD_FROM_FINN: "Add from Finn",
  ATTACHMENTS_PAGE: "Attachments Page",
  BECOME_MEMBER_PAGE: "Become Member Page",
  BREADCRUMBS: "Breadcrumbs",
  CAR_INSURANCE: "Car Insurance",
  CAR_LOAN_CONFIRMATION_PAGE: "Confirmation/Car Loan",
  CAR_LOAN: "Car Loan",
  COMMON: "Common",
  CONFIRMATION_PAGE_TRIAL_MEMBERSHIP: "Confirmation/Trial Membership",
  CONFIRMATION_PAGE: "Confirmation",
  CONTRACT_EXPLANATION_MODAL: "Contract Explanation Modal",
  CONTRACT_TYPES: "Contract Types",
  CONTRACT_UPDATES_MODAL: "Contract Updates Modal",
  CORONA_BANNER: "Corona Banner",
  FAQ_MODAL: "FAQ Modal",
  FAQ_PAGE: "FAQ Page",
  FOOTER: "Footer",
  GLOBAL_ALERT: "Global Alert",
  HEADER: "Header",
  INVITATION: "Invitation",
  LANDING_PAGE: "Landing Page",
  PAYMENT: "Payment",
  PREVIEW: "Preview",
  SAVE_CONTRACTS_MODAL: "Save Contracts Modal",
  SETTLEMENT_PAGE: "Settlement Page",
  SIGNING: "Signing",
  STATIC_CONTRACTS: "Static Contracts",
  SUMMARY_PAGE: "Summary Page",
  TRIAL_MEMBERSHIP_ALERT: "Trial Membership Alert",
  TRIAL_MEMBERSHIP: "Trial Membership",
};
