import { InfoAlert } from "@naf/teamscheme";
import styled from "styled-components";
import { useContract } from "../ContractContext";
import CancelSigningButtonLinkModal from "../signing/CancelSigningButtonLinkModal";

export function EditableStatusMessage() {
  const contract = useContract();
  const isEditable = !contract.signature;
  return !isEditable ? (
    <StyledInfoAlert>
      <p>
        Kontrakten er låst for endringer pga. påbegynt signering. Du må avbryte
        signering for å gjøre endringer i utfyllingen.
      </p>
      <CancelSigningButtonLinkModal />
    </StyledInfoAlert>
  ) : null;
}

const StyledInfoAlert = styled(InfoAlert)`
  margin-bottom: ${({ theme }) => theme.spacing.space20};
`;
