import styled from "styled-components";
import StaticHeader from "../layout/header/StaticHeader";
import LoadingBox from "./LoadingBox";

const StyledLoadingBox = styled(LoadingBox)`
  padding: ${({ theme }) => theme.spacing.space16};
`;

export function StaticLoadingScreen({ text = "Laster" }: { text?: string }) {
  return (
    <>
      <StaticHeader />
      <StyledLoadingBox text={text} />
    </>
  );
}
