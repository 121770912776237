import {
  Auth0Client,
  type User,
  type RedirectLoginOptions,
  type Auth0ClientOptions,
} from "@auth0/auth0-spa-js";
import type { Destination } from "../contract/routing/Destination";
import { RETURN_PATH_PARAM } from "../shared/utils/Location";

interface LoginOptions {
  targetUrl: string;
  locationState?: unknown;
  options?: Omit<RedirectLoginOptions, "appState" | "redirect_uri">;
}

function createClient(): Auth0Client {
  if (typeof window === "undefined") {
    return new Auth0Client({ clientId: "", domain: "" });
  }

  const location = window.location;
  const options: Auth0ClientOptions =
    location.host === "kjopekontrakt.naf.no"
      ? {
          domain: import.meta.env.VITE_AUTH0_PROD_DOMAIN,
          clientId: import.meta.env.VITE_AUTH0_PROD_CLIENT_ID,
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_PROD_AUDIENCE,
          },
        }
      : {
          domain: import.meta.env.VITE_AUTH0_STAGE_DOMAIN,
          clientId: import.meta.env.VITE_AUTH0_STAGE_CLIENT_ID,
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_STAGE_AUDIENCE,
          },
        };
  const initOptions: Auth0ClientOptions = {
    ...options,
    authorizationParams: {
      ...options.authorizationParams,
      redirect_uri: `${location.origin}/login/callback`,
      scope: "email",
    },
    useCookiesForTransactions: true,
    useRefreshTokensFallback: true,
    useRefreshTokens: true,
    cookieDomain: location?.hostname !== "localhost" ? ".naf.no" : undefined,
  };
  const client = new Auth0Client({
    ...initOptions,
  });

  return client;
}

export const auth0Client = createClient();

const ignoreErrors = [
  "login_required",
  "interaction_required",
  "consent_required",
];

export function throwAuth0Error(error: unknown) {
  const auth0Error = error as { error: string };
  if (!ignoreErrors.includes(auth0Error.error)) {
    throw error;
  }
}

async function initAuth0Client() {
  const url = new URL(window.location.href);
  const isRedirect = url.pathname === "/login/callback";
  try {
    let user: User | undefined;
    if (isRedirect) {
      if (url.searchParams.has("code") && url.searchParams.has("state")) {
        const { appState } = await auth0Client.handleRedirectCallback();
        user = await auth0Client.getUser();
        return { redirect: getRedirectCallback(appState), user };
      }
      if (url.searchParams.get("success") === "true") {
        await loginWithRedirect({ targetUrl: "/" });
      }
      return undefined;
    }

    /*
    We to getTokenSilently here and NOT run checkSession due to the possibility that users are redirected from other pages (like nafno).
    The auth0 ClientId would in that case be different and checkSession would not find the appropriate cookie to init our auth0 client.
    */
    await auth0Client.getTokenSilently();
    user = await auth0Client.getUser();
    return { user };
  } catch (error: unknown) {
    throwAuth0Error(error);
  }
}

export const initAuthResult =
  typeof window === "undefined" ? Promise.resolve() : initAuth0Client();

export function loginWithRedirect({
  targetUrl,
  locationState,
  options,
}: LoginOptions) {
  return auth0Client.loginWithRedirect({
    ...options,
    authorizationParams: {
      redirect_uri: `${document.location.origin}/login/callback`,
      ...options?.authorizationParams,
    },
    appState: {
      locationState,
      targetUrl,
    },
  });
}
function getRedirectCallback(
  appState?: { targetUrl: string } | { locationState: unknown },
) {
  const next: Destination = {
    to: {
      pathname: "/",
    },
    state: null,
  };

  if (appState && typeof appState === "object") {
    if ("targetUrl" in appState) {
      const [path, search] = appState.targetUrl.split("?");
      next.to = {
        pathname: path,
        search: search ? `?${search}` : undefined,
      };
    }
    if ("locationState" in appState) {
      next.state = appState.locationState;
    }
  }

  return next;
}

export function logout() {
  const { origin, pathname, search } = document.location;
  const query = new URLSearchParams({
    [RETURN_PATH_PARAM]: `${pathname}${search}`,
  });
  const returnTo = `${origin}/logout/callback?${query.toString()}`;
  return auth0Client.logout({ logoutParams: { returnTo } });
}
