import * as Sentry from "@sentry/browser";
import { Component, type ErrorInfo, type ReactNode } from "react";
import { StandaloneErrorPage } from "./StandaloneErrorPage";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class StandaloneErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log({ error, info });
    Sentry.withScope((scope) => {
      scope.setExtras({ info: info });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <StandaloneErrorPage>
          <h1>Feil &ndash; NAF Kjøpekontrakt</h1>
          <p>
            Det ser ut som noe gikk galt! Vennligst lukk nettleseren og åpne den
            på nytt.
          </p>
        </StandaloneErrorPage>
      );
    }

    return this.props.children;
  }
}
