import type { AppPages } from "../../routing/AppPages";
import { getAbsolutePath, type GetPath } from "../../routing/AppRoutes";
import type { EnsuredPath, PathCollection } from "../../routing/ChildRoutes";
import { useContractToken } from "../useContractToken";
import type { ContractToken } from "../model/ContractViewModel";

export type ContractPaths = PathCollection<AppPages>;

export function getContractPathFromPathname(
  pathname: string,
  token: ContractToken,
): EnsuredPath {
  const search = new URLSearchParams({ token }).toString();

  return { pathname, search: `?${search}` };
}

export function getContractPath<TPage extends AppPages>(
  token: ContractToken,
  getPath: GetPath<TPage>,
): EnsuredPath {
  const absolute = getAbsolutePath(getPath);
  return getContractPathFromPathname(absolute, token);
}

export function useContractPath<TPage extends AppPages>(
  getPath: GetPath<TPage>,
): EnsuredPath {
  const token = useContractToken();
  return getContractPath(token, getPath);
}
