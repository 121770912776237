import type { Location } from "react-router-dom";
import { AppPages } from "../../routing/AppPages";
import {
  addLocationObjectQueryParam,
  RETURN_PATH_PARAM,
} from "../../shared/utils/Location";
import type { ContractToken } from "../model/ContractViewModel";
import { getContractPath } from "../routing/ContractUrls";
import type { Destination } from "../routing/Destination";
import type { TargetState } from "../routing/ReturnDestination";

export function getContractLoginCallbackPageDestination<T>(
  currentLocation: Location<T>,
  token: ContractToken,
): Destination<TargetState<T>> {
  const { pathname: returnPath, state: targetState } = currentLocation;
  const state: TargetState<T> = { targetState };
  const loginCallback = getContractPath(token, {
    page: AppPages.ContractLoginCallback,
  });

  const withReturnPath = addLocationObjectQueryParam(
    loginCallback,
    new URLSearchParams({ [RETURN_PATH_PARAM]: returnPath }),
  );

  return { to: withReturnPath, state };
}
