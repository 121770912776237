import type { HTMLAttributes } from "react";
import styled from "styled-components";
import { formatPhoneNumber } from "./formatPhoneNumber";

interface PhoneNumberProps
  extends Omit<HTMLAttributes<HTMLSpanElement>, "children"> {
  number?: string;
}

export const PhoneNumber = styled(({ number, ...props }: PhoneNumberProps) => {
  return <span {...props}>{formatPhoneNumber(number)}</span>;
})`
  white-space: nowrap;
`;
