import { onDesktop } from "@naf/teamscheme";
import styled, { css } from "styled-components";

export const HintTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme: { spacing, fontStyle } }) => css`
    ${fontStyle.bodyText.bodyText}
    line-height: ${spacing.space20};
    margin-left: ${spacing.space24};
    ${onDesktop`
      margin-left: ${spacing.space12};
    `}
  `}
`;
