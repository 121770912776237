import type { ContractToken } from "./model/ContractViewModel";
import {
  contractFetcher,
  type FetchContractResult,
} from "../api/contractFetcher";

const cache: { [token: string]: FetchContractResult } = {};

async function fetchContractInner(
  token: ContractToken,
): Promise<FetchContractResult> {
  await contractFetcher.load(token);

  if (!contractFetcher.state.fetchResult) throw new Error("No fetch result");

  return contractFetcher.state.fetchResult;
}

export async function fetchContract(
  token: ContractToken,
  opts = { resetCache: false },
): Promise<FetchContractResult> {
  if (!opts.resetCache && cache[token]) return cache[token];

  cache[token] = await fetchContractInner(token);

  return cache[token];
}
