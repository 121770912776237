import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { useSyncExternalStore } from "react";
import type {
  ContractToken,
  ContractViewModel,
} from "../contract/model/ContractViewModel";
import type { UpdateNotification } from "../contract/updates/UpdateNotification";
import UpdateType from "../contract/updates/UpdateType";
import { ConnectionStatus } from "./ConnectionStatus";
import type { TicketViewModel } from "./TicketViewModel";
import { webSocketManager } from "./WebSocketManager";
import { sendSubscriptionRequest } from "./SubscriptionManager";
import { api, type ApiError } from "./ApiClient";
import { captureException } from "@sentry/browser";
import type { UnauthorizedContractErrorResult } from "../contract/UnauthorizedContractErrorResult";
import type { ContractSubscriptionSubject } from "./ContractSubscriptionSubject";

export interface ConflictResult {
  archived: boolean;
  quarantined: boolean;
}

export interface GetSalesContractViewResult {
  contract: ContractViewModel;
  ticket: TicketViewModel<ContractSubscriptionSubject>;
}

export interface LoadedContract {
  contract: ContractViewModel;
  role: Role;
  ticket: TicketViewModel<ContractSubscriptionSubject>;
}

export type FetchContractResult =
  | { conflict: ConflictResult }
  | { loaded: LoadedContract }
  | { unauthorized: UnauthorizedContractErrorResult };

async function fetchContract(
  token: ContractToken,
): Promise<FetchContractResult> {
  try {
    const getResult = await api.get<GetSalesContractViewResult>(
      `contract/token/${token}`,
    );
    const contract = getResult.contract;
    const role =
      contract && "buyerToken" in contract ? Role.Buyer : Role.Seller;

    return { loaded: { contract, role, ticket: getResult.ticket } };
  } catch (error: unknown) {
    console.log({ error });
    const apiError = error as ApiError;
    if (apiError.status === 409) {
      const conflictResult = apiError.result as ConflictResult;
      return { conflict: conflictResult };
    }

    if (apiError.status === 401) {
      const unauthorizedResult =
        apiError.result as UnauthorizedContractErrorResult;
      return { unauthorized: unauthorizedResult };
    }

    captureException(apiError);

    throw new Error("Failed to fetch contract");
  }
}

class ContractFetcher {
  state: {
    token: ContractToken | null;
    fetchResult: FetchContractResult | null;
    isSubscribed: boolean;
    subscriptionLoading: boolean;
    isFetching: boolean;
    isDisconnected: boolean;
  } = {
    token: null,
    fetchResult: null,
    isSubscribed: false,
    subscriptionLoading: false,
    isFetching: false,
    isDisconnected: false,
  };

  private _updateState(state: Partial<ContractFetcher["state"]>) {
    this.state = { ...this.state, ...state };
    this._emit();
  }

  constructor() {
    webSocketManager.subscribeToStatus(() => {
      if (webSocketManager.status === ConnectionStatus.Disconnected) {
        this._updateState({ isSubscribed: false, isDisconnected: true });
      }

      if (webSocketManager.status === ConnectionStatus.Connected) {
        if (this.state.token && this.state.isDisconnected) {
          this.load(this.state.token);
        }
      }
    });
  }

  async load(token: ContractToken): Promise<FetchContractResult> {
    this._updateState({
      token,
      fetchResult: null,
      isSubscribed: false,
      subscriptionLoading: false,
      isFetching: true,
      isDisconnected: false,
    });

    const fetchResult = await fetchContract(token);

    this._updateState({
      isFetching: false,
      fetchResult,
    });

    if ("loaded" in fetchResult) {
      this._updateState({ subscriptionLoading: true });
      waitForWebSocketOpened().then(async () => {
        sendSubscriptionRequest(
          "ContractSubscriptionSubject",
          fetchResult.loaded.ticket,
        );

        await waitForSubscriptionEstablished(fetchResult.loaded.ticket);
        this._updateState({
          isSubscribed: true,
          subscriptionLoading: false,
        });
      });
    }

    return fetchResult;
  }

  private _statusListeners: (() => void)[] = [];

  subscribeToStatus(listener: () => void) {
    this._statusListeners.push(listener);

    return () => {
      const index = this._statusListeners.indexOf(listener);
      if (index !== -1) {
        this._statusListeners.splice(index, 1);
      }
    };
  }

  private _emit() {
    for (const listener of this._statusListeners) listener();
  }
}

function waitForWebSocketOpened() {
  return new Promise<void>((resolve) => {
    if (webSocketManager.status === ConnectionStatus.Connected) {
      resolve();
    } else {
      const cleanup = webSocketManager.subscribeToStatus(() => {
        if (webSocketManager.status === ConnectionStatus.Connected) {
          cleanup();
          resolve();
        }
      });
    }
  });
}

function waitForSubscriptionEstablished<TSubject extends object>(
  ticket: TicketViewModel<TSubject>,
) {
  return new Promise<void>((resolve) => {
    const cleanup = webSocketManager.onMessage((event) => {
      const update = JSON.parse(event.data) as UpdateNotification;
      if (update.updateType === UpdateType.SubscriptionCreated) {
        if (update.ticketId === ticket.ticketId) {
          cleanup();
          resolve();
        }
      }
    });
  });
}

export const contractFetcher = new ContractFetcher();

const subscribe = (onStoreChange: () => void) =>
  contractFetcher.subscribeToStatus(onStoreChange);

export function useContractFetcherState() {
  return useSyncExternalStore(
    subscribe,
    () => contractFetcher.state,
    () => contractFetcher.state,
  );
}
