import { createContext, useContext, useEffect } from "react";
import type { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import type {
  CtaClickProps,
  TrackEventProps,
  TrackingValue,
} from "@naf/teamscheme";
import type { ContractViewModel } from "../contract/model/ContractViewModel";

export interface ITrackingContext {
  ctaClick(props: CtaClickProps): void;
  event(props: TrackEventProps): void;
  pageView({ title, path }: { title: string; path: string }): void;
  contractLoad(contract: ContractViewModel, role: Role): void;
  experiment(experimentName: string): void;
  appLoad(): void;
  context(contextObject: { [attribute: string]: TrackingValue }): void;
}

export const TrackingContext = createContext<ITrackingContext | null>(null);

export function useTracking() {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error("No AppTrackingContext found!");
  }
  return context;
}

export function useTrackContext(
  attribute: string,
  value: TrackingValue | null | undefined,
) {
  const tracking = useTracking();
  useEffect(() => {
    if (value !== null && value !== undefined) {
      tracking.context({ [attribute]: value });
    }
  }, [attribute, tracking, value]);
}
