import type { LoaderFunction } from "react-router-dom";
import type { ContractToken } from "./model/ContractViewModel";
import { fetchContract } from "./FetchContract";
import type { FetchContractResult } from "../api/contractFetcher";

export const contractLoader: LoaderFunction = async ({
  request,
}): Promise<null | FetchContractResult> => {
  if (typeof window === "undefined") return null;
  const token = new URL(request.url).searchParams.get("token");
  if (!token) return null;

  return fetchContract(token as ContractToken);
};

contractLoader.hydrate = false;
