import type { HTMLAttributes } from "react";
import styled from "styled-components";

export const PageContent = styled(function ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={[className, "PageContent"].filter((c) => c).join(" ")}
    />
  );
})`
  width: 100%;
  max-width: ${8 * 90}px;
`;
