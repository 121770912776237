import { Link } from "react-router-dom";
import styled from "styled-components";
import { NafLogo } from "../../shared/logos/NafLogo";

// TODO: kan det være at denne får klassekonflikt fordi
// <BrowserRouter> og <StaticRouter> gjør at styled-ssr genererer forskjellige
// klassenavn for dette komponentet?
const LogoLinkWrapper = styled(Link)`
  display: flex;
`;
export function HeaderLogo({
  linkLocation,
}: {
  linkLocation?: HeaderLogoLinkTarget;
}) {
  const logo = <NafLogo size={40} />;
  if (!linkLocation) return logo;
  return <LogoLinkWrapper to={linkLocation}>{logo}</LogoLinkWrapper>;
}

export type HeaderLogoLinkTarget = string | Partial<Location>;
