export enum SchemaType {
  Default = 0,
  Car = 1,
  Motorbike = 2,
  Trailer = 4,
  Camper = 8,
  CampingTrailer = 16,
}
export enum SchemaTypeName {
  Default = "Default",
  Car = "Car",
  Motorbike = "Motorbike",
  Trailer = "Trailer",
  Camper = "Camper",
  CampingTrailer = "CampingTrailer",
}

export const SCHEMA_TYPES = {
  [SchemaTypeName.Default]: SchemaType.Default,
  [SchemaTypeName.Car]: SchemaType.Car,
  [SchemaTypeName.Motorbike]: SchemaType.Motorbike,
  [SchemaTypeName.Trailer]: SchemaType.Trailer,
  [SchemaTypeName.Camper]: SchemaType.Camper,
  [SchemaTypeName.CampingTrailer]: SchemaType.CampingTrailer,
};

export const SCHEMA_TYPE_NAMES: { [key: string]: SchemaTypeName } = (<
  SchemaTypeName[]
>Object.keys(SCHEMA_TYPES)).reduce<{ [key: string]: SchemaTypeName }>(
  (acc, e) => {
    acc[SchemaType[e]] = e;
    return acc;
  },
  {},
);
