enum UpdateType {
  Fields = 0,
  Signature = 1,
  InvitationAccepted = 2,
  RoleSpecificInfo = 4,
  Payment = 8,
  BankIdPayment = 16,
  ContractType = 32,
  OwnershipTransfer = 64,
  Settlement = 128,
  OwnershipTransferFields = 256,
  OwnershipTransferValidation = 512,
  SubscriptionCreated = 1024,
}
export default UpdateType;
