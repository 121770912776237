import { useState, useEffect } from "react";
import { useContractHeaderContext } from "./ContractHeaderContext";
import { SavingState } from "./SavingState";

export function useSavingState(timeout?: number) {
  const { isSaving, saveError } = useContractHeaderContext();
  const [wasSaving, setWasSaving] = useState(false);
  const [successfulSave, setSuccessfulSave] = useState(false);
  const timeoutDelay = timeout ?? 5000;

  useEffect(() => {
    if (isSaving) {
      setWasSaving(true);
    }
  }, [isSaving]);

  useEffect(() => {
    if (!isSaving && wasSaving && !saveError) {
      setSuccessfulSave(true);
    }
  }, [isSaving, saveError, wasSaving]);

  useEffect(() => {
    if (successfulSave) {
      const timeout = setTimeout(() => {
        setSuccessfulSave(false);
        setWasSaving(false);
      }, timeoutDelay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [successfulSave, timeoutDelay]);

  if (saveError) return SavingState.Error;
  if (isSaving) return SavingState.Loading;
  if (successfulSave) return SavingState.Saved;
  return SavingState.None;
}
