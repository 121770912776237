import { createContext, useContext } from "react";

export interface IAuthContext {
  isLoading: boolean;
  isInitialized: boolean;
  isAuthenticated: boolean;
  email: string | null;
  isAdmin: boolean;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export function useAuth() {
  const auth0Context = useContext(AuthContext);
  if (!auth0Context) throw new Error("No Auth0Context found!");
  return auth0Context;
}
