import { onDesktop } from "@naf/teamscheme";
import type { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { flowHeaderPaddingHorizontal } from "./HeaderPadding";
import { HeaderVariant } from "./HeaderVariant";

interface HeaderWrapperProps extends HTMLAttributes<HTMLDivElement> {
  variant: HeaderVariant;
}
export const HeaderWrapper = styled(function ({
  variant: _,
  ...props
}: HeaderWrapperProps) {
  return <header {...props} />;
})`
  ${({ theme }) => flowHeaderPaddingHorizontal(theme)}
  background-color: ${({ theme }) => theme.nafColor.primary.dew};

  ${({ theme: { spacing } }) => css`
    padding-top: ${spacing.space16};
    padding-bottom: ${spacing.space16};

    ${onDesktop`
        padding-top: ${spacing.space24};
        padding-bottom: ${spacing.space24};
      `}
  `}

  ${(props) =>
    props.variant === HeaderVariant.Flow &&
    css`
      background: ${props.theme.nafColor.signature.white};
    `};

  ${(props) =>
    props.variant === HeaderVariant.Static &&
    css`
      background: transparent;
    `};

  /* Copied because it is propagated from Header. mistake? */
  min-height: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
