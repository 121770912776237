import { Role } from "#vehicle-contract/common/lib/model/contract/Role";

export function roleName(role: Role) {
  switch (role) {
    case Role.Seller:
      return "Seller";
    case Role.Buyer:
      return "Buyer";
    default:
      throw new Error(`Unrecognized role ${role}`);
  }
}
