import { useMemo } from "react";
import { useContract } from "./ContractContext";
import type { ContractToken } from "./model/ContractViewModel";
import { getContractToken } from "./getContractToken";

export function useContractToken(): ContractToken {
  const contract = useContract();

  return useMemo(() => getContractToken(contract), [contract]);
}
