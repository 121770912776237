import type {
  ContractViewModel,
  ContractToken,
} from "./model/ContractViewModel";

export function getContractToken(contract: ContractViewModel): ContractToken {
  if ("buyerToken" in contract) return contract.buyerToken;
  if ("sellerToken" in contract) return contract.sellerToken;
  throw new Error("No token found in contract");
}
