import type { LoaderFunction } from "react-router-dom";
import type { Destination } from "../contract/routing/Destination";
import { initAuthResult } from "./auth0Client";

export interface LoginCallbackLoaderResult {
  redirect?: Destination;
}

export const loginCallbackLoader: LoaderFunction =
  async (): Promise<LoginCallbackLoaderResult | null> => {
    if (typeof window === "undefined") return null;
    return (await initAuthResult) ?? null;
  };

loginCallbackLoader.hydrate = false;
