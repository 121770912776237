import type { ReactNode } from "react";
import styled from "styled-components";
import Breadcrumbs from "../../shared/elements/Breadcrumbs";
import { useContractPath } from "../routing/ContractUrls";
import { AppPages } from "../../routing/AppPages";

function useContactRootBreadcrumbs() {
  const contractLanding = useContractPath({ page: AppPages.Contract });
  return {
    action: { linkTo: contractLanding },
    label: "Din oversikt",
  };
}

const ReturnLinkWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.space12};
`;

function ReturnToLandingPage() {
  const rootBreadcrumbs = useContactRootBreadcrumbs();
  return (
    <ReturnLinkWrapper>
      <Breadcrumbs items={[rootBreadcrumbs]} />
    </ReturnLinkWrapper>
  );
}

export default ReturnToLandingPage;

export function ContractBreadcrumb({ title }: { title?: ReactNode }) {
  const rootBreadcrumbs = useContactRootBreadcrumbs();
  const currentPage = {
    label: title,
  };
  return (
    <ReturnLinkWrapper>
      <Breadcrumbs items={[rootBreadcrumbs]} currentPage={currentPage} />
    </ReturnLinkWrapper>
  );
}
