import { Button, type ButtonButtonElementProps } from "@naf/teamscheme";
import { ButtonLinkModal, type ButtonLinkModalProps } from "@naf/teamscheme";
import { captureException } from "@sentry/core";
import React, { useEffect, useRef, useState } from "react";
import { EVENTS } from "../../tracking/EVENTS";
import type { ApiError } from "../../api/ApiClient";
import { useContractClient } from "../../api/ContractClientContext";
import ErrorMessage from "../../shared/error/ErrorMessage";
import { makeCancelable } from "../../utils";
import { useContractContext } from "../ContractContext";

function useIsMounted() {
  const ref = useRef(false);
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  return () => ref.current;
}

export const CancelSigningButton = ({
  className = "",
  title = "Avbryt signering",
  ...props
}: Omit<ButtonButtonElementProps, "children" | "onClick">) => {
  const client = useContractClient();
  const { contract, applyContractUpdates } = useContractContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isMounted = useIsMounted();

  async function removeSignature() {
    setLoading(true);

    const uri = `contract/${contract?.id}/document/${contract?.signature?.hash}/signature`;
    const cancelable = makeCancelable(client.delete(uri));

    try {
      await cancelable.promise;

      applyContractUpdates((contract) => ({
        ...contract,
        signature: null,
      }));
    } catch (e) {
      const error = e as { isCanceled: true } | ApiError;
      if (!("isCanceled" in error && error.isCanceled)) {
        setError((error as ApiError).message);
      }
      captureException(error);
    } finally {
      if (isMounted()) setLoading(false);
    }
  }

  const classes = `${className} CancelSigningButton`;

  return (
    <span className={classes}>
      <Button
        {...props}
        variant="primary"
        onClick={removeSignature}
        disabled={loading}
        trackEvent={EVENTS.SIGNING.CANCEL}
        isLoading={loading}
      >
        {title}
      </Button>
      {error ? (
        <ErrorMessage>
          Noe gikk galt! Oppdater siden og prøv igjen.
        </ErrorMessage>
      ) : null}
    </span>
  );
};

function CancelSigningButtonLinkModal({
  ...props
}: Omit<ButtonLinkModalProps, "children" | "title" | "variant">) {
  return (
    <ButtonLinkModal title="Avbryt signering" variant="secondary" {...props}>
      <h1>Vil du avbryte?</h1>
      <p>
        Ved å avbryte signeringsprosessen blir registrerte signaturer forkastet.
        Kontrakten vil åpnes for endringer. Begge parter må signere for at
        kontrakten skal være gyldig. Motpart blir varslet om at du har avbrutt
        signering.
      </p>
      <CancelSigningButton title="Avbryt signering nå" />
    </ButtonLinkModal>
  );
}

export default CancelSigningButtonLinkModal;
